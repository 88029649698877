@import '../../utilities/variables.scss';

.Work__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    scroll-margin-top: 50px;
}

.Work__subHeadingText {
    font-weight: 600;
    margin-bottom: 40px;
}

.CompanyLogo_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    white-space: nowrap;
    padding-bottom: 40px;
}

.Logo {
    max-width: 400px;
    height: 30px;
    padding: 30px;

    @media (max-width: $mobileSize) {
        max-width: 200px;
    }
}

.ExtraPadding {
    padding: 0 20px ;
    padding-bottom: 40px;

    @media (max-width: $mobileSize) {
        width: 60%;
        padding-bottom: 10px;
    }
}

.Projects__container {
    display: flex;
    width: 60%;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: $mobileSize) {
        flex-direction: column;;
    }
}