@import '../../utilities/variables.scss';

.SkillSection_container {
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 50%;
    min-width: 40%;
    border-width: 2px;
    border-radius: 20px 0 0 20px;
    border-color: $primaryColor;
    border-style: solid;
    background-color: $secondaryColor;
    padding: 20px;
    align-items: center;
    text-align: center;
    justify-content: space-evenly ;

    
    @media (max-width: $mobileSize) {
        border-radius: 20px;
        margin-bottom: 20px;
    }
}

.RightBox {
    border-radius: 0 20px 20px 0;
    border-width: 2px 2px 2px 0;

    @media (max-width: $mobileSize) {
        border-radius: 20px;
        border-width: 2px;
    }
}

.ExplanationText__container{
    display: flex;
    width: 75%;
}

.SkillSection__heading {
    color: $primaryColor;
}