@import './../../utilities/variables.scss';


.Section__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 90px;

    @media (max-width: $mobileSize) {
        margin-top: 40px;
    }
}

.ExtraMargin {
    margin-bottom: 40px;
}

.ContactMe__container {
    display: flex;
    background-color: $contrastingColor;
    height: 150px;
    width: 40%;
    min-width: 320px;
    border-radius: 16px;
    align-items: center;
    justify-content: space-around ;
    padding: 8px;

    @media (max-width: $mobileSize) {
        flex-direction: column;
        min-width: 220px;
    }
}

.ButtonStyling {
    align-items: center;
    width: 20%;
    color: white;
    height: 50%;
    border: $buttonBorderColor;
    border-style: solid;
    border-radius: 16px;

    &:hover {
        background-color: $buttonBorderColor;
        color: $contrastingColor
    }

    @media (max-width: $mobileSize) {
        height: auto;
        width: 100px;
    }

}

.DescriptionStyling {
    color: white;
    width: 70%;
    
    @media (max-width: $mobileSize) {
        width: 100%;

    }
}

.ContactText {
    font-size: 18px;

    @media (max-width: $mobileSize) {
        font-size: 13px;
        text-align: center;
    }
}