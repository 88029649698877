@import './../../utilities/variables.scss';

.Card__container {
    position: relative;
    text-align: center;
    width: 200px;
    height: 180px;
    max-width: 200px;
    max-height: 180px;
}

.Card__image { 
    width: 200px;
    height: 180px;
    max-width: 200px;
    max-height: 180px;
    opacity: 0.6;
    border-radius: 20px;
}

.Overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: $contrastingColor;
    border-radius: 20px;
    color: white;
    
    &:hover {
        opacity: 1;
    }
}

.Card__textTop{
    position: absolute;
    font-size: 28px;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
}

.Card__textCenter{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}