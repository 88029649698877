@import './../../utilities/variables.scss';

.NavBar {
    height: 50px;
    background-color: $secondaryColor;
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: center;

    @media (max-width: $mobileSize) {
        align-items: flex-start;
    }
}

.NavBar__buttonContainer {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-between;
}

.Homepage__container {
    background: $secondaryColor;
    height: 100vh;
    overflow-x: hidden;
}


.Homepage__heading {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 300px;
}

.Homepage__headingText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    @media (max-width: $mobileSize) {
        padding: 10px;
    }
}

.HeadingText {
    font-size: 120px;
    font-weight: 400;
    line-height: 0.8;
    
    @media (max-width: $mobileSize) {
        font-size: 36px;
    }
}

.CustomButton__container{
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    max-width: 500px;
    flex-wrap: wrap;
    white-space: nowrap;
}

.WindowsToolbar {    
    @media (max-width: $mobileSize) {
        display: none;
    }
}

.MobileToolbar {
    display: none;

    @media (max-width: $mobileSize) {
        display: block;
    }
}

.MobileDrawer { 
    display: none;
    min-width: 100px;
    padding: 20px;

    @media (max-width: $mobileSize) {
        display: block
    }
}

.HamburgerMenuButton {
    display: none;

    @media (max-width: $mobileSize) {
        justify-content: center;
        align-items: center;
        display: block;
        padding-left: 0 !important;
        padding-top: 15px;
    }
}

.NoPadding {
    padding: 0;
    justify-content: center;
    align-items: center;
    vertical-align: center;
}