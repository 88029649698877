@import '../../utilities/variables.scss';

.Section__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.Footer__container {
    display: flex;
    flex-direction: column;
    background-color: $primaryColor;
    height: 360px;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 30px 0 0 0 ;
    margin-top: -56px;
}

.Quote__container {
    width: 25%;
    padding-top: 60px;
    color: white;

    @media (max-width: $mobileSize) {
        width: 90%;
    }
}

.SocialMedia__container {
    display: flex;
    width: 50%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.SocialMediaButton {
    height: 60px;
    width: 60px;
    border-radius: 40px;
    padding: 4px;
    display :flex;
    color: white;
    border-color: white;
    border-style: solid;
    border-width: 2px;
    margin: 10px;
    transition: 0.5s;

    &:hover {
        color: black;
        border-color: $buttonBorderColor;
        background-color: $buttonBorderColor;
    }
}

.Icon{
    height: 50px;
    width: 50px;
}

.BackToTopButton {
    width: 160px;
    border-radius: 16px;
    padding: 4px;
    display :flex;
    color: white;
    border-color: white;
    border-style: solid;
    border-width: 2px;
    margin: 10px;
    transition: 0.5s;

    &:hover {
        color: black;
        border-color: $buttonBorderColor;
        background-color: $buttonBorderColor;
    }
}
