@import './../../utilities/variables.scss';

.Section__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 90px;
    scroll-margin-top: 50px;

    @media (max-width: $mobileSize) {
        margin-bottom: 40px;
    }
}

.AboutMe__container {
    display: flex;
    background-color: $primaryColor;
    height: 460px;
    width: 100%;
    justify-content: center;
    padding: 30px 0 0 0 ;

    @media (max-width: $mobileSize) {
        padding: 10px;
        flex-direction: column;
        align-items: center;
        height: 340px;
        padding: 0;
        justify-content: flex-start;
        padding-top: 40px;
    }
}

.AboutMe__textContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
    min-width: 510px;
    align-items: center;
    text-align: center;

    @media (max-width: $mobileSize) {
        width: 75%;
        min-width: 250px;
    }
}

.AboutMe__subHeadingText {
    color: white;
    font-weight: 600;
    margin-bottom: 40px;

    @media (max-width: $mobileSize) {
        margin-bottom: 20px;
    }
}

.SkillSection__container {
    display: flex;
    width: 40%;
    min-width: 400px;
    margin-top: -100px;

    @media (max-width: $mobileSize) {
        padding: 10px;
        flex-direction: column;
        align-items: center;
    }
}