@import './variables.scss';

.SubheadingText {
    font-size: 48px;
    font-weight: 200;
    
    @media (max-width: $mobileSize) {
        font-size: 24px;
    }
}

.DescriptionText {
    font-size: 28px;
    
    @media (max-width: $mobileSize) {
        font-size: 16px;
    }
}

.BigButtonText {
    font-size: 26px;
}

.ExplanationText {
    font-size: 18px;
}

.WhiteText {
    color: white;
}