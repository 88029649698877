@import './../../utilities/variables.scss';

.ButtonStyling {
    min-width: 130px;
    max-width: 130px;
    height: 30px;
    color: black;
    border-radius: 8px;
    transition: 0.3s;
    text-align: left;
    margin: 0 10px;

    &:hover {
        border-color: none;
        background: #BDBDBD;
    } 
}

.ButtonText {
    font-size: 20px;
    font-weight: 400;
    padding: 10px;
}